@import 'messages/variables';

.deleted-message {
  background-color: rgba(255, 0, 0, 0.068);
  border: solid 1px $red;
}

.blocked-user {
  color: $red;
}

.shop-avatar-thumbnail, .customer-avatar-thumbnail, .broadcast-title-image {
  max-height: 1.5rem;
}

.shop-avatar, .customer-avatar, .broadcast-title-image {
  max-height: 3rem;
}

.shop-avatar-medium {
  width: 20rem;
}
