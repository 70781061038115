.invitation-modal {
  .form-group {
    margin-top: 2rem;
  }
}

.excel-upload {
  color: $chat-input-icons-color;
  font-size: 1.5rem;
}
