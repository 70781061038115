$login-image-breakpoint: 32rem;

.login-container {
  display: flex;

  h1 {
    font-weight: 100;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 100;
    margin: 2rem 0;
  }


  .login-box {
    min-width: 100%;
    padding: 4em 5vw 4rem;
    transition: all ease .5s;

    @media (min-width: $login-image-breakpoint) {
      min-width: $login-image-breakpoint;
      padding: 4em 5vw 12rem;
      width: 50vw;
    }

    .login-form {
      max-width: 25rem;
    }

    .btn-primary {
      color: $black;
      padding: 1rem;
      width: 100%;
    }
  }

  .login-image-box {
    width: 50vw;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}
