$subtitles-color: #b0b0b0;
$download-button-height: 4.4rem;
$download-banner-color: #9f9;

.static-page {
  padding: 2.75rem 0;

  h1 {
    color: $gray-700;
    font-weight: 200;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 3.1rem;
    }
  }

  h3 {
    color: $green;
    font-weight: 200;
    margin: 2rem 0 1rem;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 2rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
  }

  address {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
  }

  strong {
    text-decoration: underline;
  }

  p,
  li {
    font-weight: 300;
  }
}

.infos-page {
  .laptop-container {
    margin-bottom: 1em;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    .laptop-container {
      margin-bottom: 0;

      img {
        height: 400px;
        width: auto;
      }
    }
  }
}

.features-img {
  max-height: 26rem;
}

.features-subtitle {
  color: $subtitles-color;
}

.dropdown-info {
  .collapsed {
    .dropdown-arrow {
      transform: rotate(0deg);
    }
  }

  .dropdown-arrow {
    transform: rotate(90deg);
    transition: transform .2s ease-in-out;
  }
}

.download-image {
  img {
    height: 32rem;
    margin-top: -4rem;
  }
}

.btn-download {
  font-size: 1.125rem;
  line-height: $download-button-height;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-down(xs) {
    line-height: $download-button-height - 1rem;
  }
}

.app-download-image {
  height: calc(#{$download-button-height} - 1.5rem);

  @include media-breakpoint-up(md) {
    height: calc(#{$download-button-height} - 0.6rem);
  }
}

.dropdown-body {
  margin-left: 1rem;
  padding-top: 1rem;
}

.banner {
  background-color: $light-green;
  margin-top: 1.87rem;
  width: 100%;

  .container {
    padding-bottom: 1.56rem;
    padding-top: 1.56rem;
    text-align: center;
  }
}

.dropdown-title {
  a {
    color: $body-color;
    font-weight: 300;
    text-decoration: none;
  }
}

.divider {
  @include media-breakpoint-up(lg) {
    border-right: 0.125rem solid #808080;
  }
}

.grid-divider {
  @include media-breakpoint-up(md) {
    border-right: 0.125rem solid #808080;
  }
}

.pricing {
  h2 {
    font-weight: 400;
  }

  .favored-plan-banner {
    font-size: 1.25rem;
    font-weight: 300;

    &.green {
      color: $green;
    }

    &.white {
      color: $white;
    }
  }

  h3 {
    color: $gray-700;
  }

  h4 {
    color: $green;
  }

  .price {
    color: $green;
    font-weight: 500;
  }

  .btn-home {
    width: 65%;
  }

  p {
    color: $gray-700;
    font-size: 1.2rem;
  }
}

.rotate-3-deg {
  transform: rotate(3deg);
}

.checkbox-list {
  list-style: none;
  padding-left: 0;

  li {
    color: $gray-700;
    display: inline-flex;
    flex: 1 0 auto;
    font-size: 1.2rem;
    margin: .2em 0;
    width: 100%;
  }

  .checkbox {
    background-color: $blue;
    border-radius: 2px;
    color: $white;
    font-size: 1.7em;
    height: 40px;
    margin-right: .5em;
    padding-left: 8px;
    padding-right: 2px;
    width: 40px;
  }
}

.pricing-iphone {
  max-width: 170px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 225px;
  }

  @include media-breakpoint-up(md) {
    max-width: 250px;
  }
}

.contact-us {
  p {
    color: $gray-700;
    font-size: 1.6rem;
    font-weight: 300;

    a {
      color: $gray-700;
      text-decoration: underline;
    }
  }
}

.faq-shop-info {
  .btn.btn-outline-primary {
    border-color: $gray-700;
    border-radius: 0.25rem;
    border-width: 0.08rem;
    color: $gray-500;
    display: inline-block;
    font-size: 1.1rem;

    margin: 1.25rem;
    padding: 0 4rem;
    text-decoration: none;

    &:hover {
      background-color: #d3d3d3;
      color: #fff;
    }
  }
}

.logo-bullet-point {
  a {
    font-weight: 300;
    text-transform: uppercase;
  }

  img {
    height: 1.6rem;
  }
}

p.colored-keywords {
  font-weight: 200;

  span {
    color: $green;
  }
}

.training-material {
  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .video-container {
    max-width: 100%;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    @include media-breakpoint-down(md) {
      height: 0;
      padding-bottom: 56.25%;
    }
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}
