.panel-toggles {
  box-shadow: rgba(204, 204, 204, 0.8) 0 2px 5px;
  display: inline-block;

  @include media-breakpoint-up(sm) {
    margin-left: $panel-margin-side;
  }

  .panel-toggle {
    border: none;
    position: relative;
    z-index: 1;

    &, &:focus {
      box-shadow: none;
    }
  }
}
