$gap: 16px;
$title-size: 18px;
$content-size: 16px;

.Toastify {
  .Toastify__toast-container {
    font-size: $title-size;
    line-height: $title-size;

    @media screen and (max-width: 500px) {
      margin: 0 5vw;
      max-height: 30vh;
      width: 90vw;
    }
  }

  .Toastify__toast {
    margin-bottom: $gap;
    padding: $gap;

    &:hover {
      filter: brightness(95%);
    }
  }

  .Toastify__toast-body {
    display: grid;
    grid-template-areas: 'icon title' 'icon content';
    grid-template-columns: $title-size + $gap auto;
    grid-template-rows: auto auto;

    i {
      grid-area: icon;
    }

    div {
      &:first-of-type {
        font-weight: 600;
        grid-area: title;
      }

      &:last-of-type {
        font-size: $content-size;
        grid-area: content;
        line-height: $content-size + 3px;
        padding-top: $gap * 0.75;
        word-break: break-word;
      }
    }

    & + i {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }
}
