$chat-header-height: 4rem;
$height-without-header: calc(100vh - #{$chat-header-height});

.rooms-container {
  display: flex;
  flex-direction: column;
  grid-area: list;
  justify-content: space-between;
}

.customer-invitation {
  box-shadow: $chat-input-shadow;
  margin-right: $list-and-chat-gap;
  margin-top: 2rem;
  padding: 18px;
  position: relative;
}

.wrapper-app {
  .alert {
    bottom: 0;
    left: $chat-gap;
    position: fixed;
    right: $chat-gap;
    z-index: 5;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .not-ready {
    position: fixed;
  }

  .messages-container {
    margin-top: 0;
  }

  .panel {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .message + .message {
    margin-top: 0;
  }

  .panel.with-chat {
    -webkit-overflow-scrolling: touch;
    grid-template-areas: 'chat' 'input';
    grid-template-columns: none;
    grid-template-rows: 1fr;
    height: $height-without-header;

    &.direct .chat .name {
      display: none;
    }

    .chat {
      margin-top: 0;
      padding-left: $list-and-chat-gap;

      .message {
        grid-row-gap: 4px;

        .text {
          font-size: 15px;
        }

        &:not(.planned) .text {
          padding: 3px 20px 3px 10px;
        }
      }
    }

    .input {
      margin-top: 0;
    }
  }

  .input {
    margin-bottom: 0;
    margin-right: 0;

    .alert {
      left: 0;
      position: relative;
      right: 0;
    }

    textarea {
      padding-left: $chat-input-padding;
      padding-right: calc(55px + 0.75rem);
    }

    input[type='file'], input[type='file'] + .fal {
      left: -37px;
    }
  }

  .panel-toggles {
    margin-left: 0;
  }

  .rooms-container {
    display: flex;
    height: $height-without-header;

    .btn {
      margin: 0 $chat-gap;
    }

    .no-rooms {
      font-size: $chat-msg-text-size;
      text-align: center;
    }
  }

  .list {
    align-self: auto;

    .item {
      width: 100%;
    }

    &::after {
      box-shadow: $panel-shadow;
      width: 100%;
    }
  }

  .chat-header {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    height: $chat-header-height;
    overflow: hidden;
    text-overflow: ellipsis;

    @include avatar($chat-avatar-size - 10px, $chat-shadow-light-color, $chat-shadow-size);

    .avatar + div {
      overflow: hidden;
      padding: 0 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .initiate-messaging {
    font-size: 0.9rem;
  }

  .customer-invitation {
    margin-right: 0;
    margin-top: 0;
  }
}

.content {
  .actions {
    display: block !important;
    font-size: 0.75rem;
    position: absolute;
    right: 3px;
    top: 3px;

    * {
      cursor: pointer;
      margin-right: 5px;
    }

    & + .text {
      padding-top: $chat-msg-padding-height;
    }
  }
}
