@use 'sass:math';

$circle-width: 0.12em;
$circle-width-hover: 0.09em;

$primary-color: #000;
$secondary-color: #dfe8ed;
$bg-color: #fff;

$primary-color-span: #3c4761;

.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

@mixin rotate($degrees) {
  transform: rotate($degrees);
}

.pie {
  border: $circle-width solid $primary-color;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  height: 1 - (2 * $circle-width);
  position: absolute;
  width: 1 - (2 * $circle-width);
  @include rotate(0deg);
}

.pie-fill {
  @include rotate(180deg);
}

.pie-chart {
  background-color: $secondary-color;
  border-radius: 50%;
  float: none;
  font-size: 5rem;
  height: 1em;
  margin: 0 auto;
  position: relative;
  width: 1em;

  *, *::before, *::after {
    box-sizing: content-box;
  }

  > span {
    color: $primary-color-span;
    display: block;
    font-size: 0.28em;
    left: 0;
    line-height: 3.7em;
    position: absolute;
    text-align: center;
    top: 0;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease-out;
    white-space: nowrap;
    width: 3.6em;
    z-index: 1;
  }

  &::after {
    background-color: $bg-color;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 1 - (2 * $circle-width);
    left: $circle-width;
    position: absolute;
    top: $circle-width;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease-in;
    width: 1 - (2 * $circle-width);
  }

  .slice {
    clip: rect(0, 1em, 1em, 0.5em);
    height: 1em;
    position: absolute;
    width: 1em;
  }

  .bar {
    border-color: $primary !important;
    @extend .pie;
  }

  @for $j from 51 through 100 {
    &.p#{$j} .slice {
      @extend .rect-auto;
    }

    &.p#{$j} .bar::after {
      @extend .pie-fill;
    }

    &.p#{$j} .fill {
      border-color: $primary !important;
      @extend .pie;
      @extend .pie-fill;
    }
  }

  @for $j from 1 through 100 {
    &.p#{$j} .bar {
      @include rotate((math.div(360, 100)*$j) + deg);
    }
  }

  &:hover {
    cursor: default;

    > span {
      color: $primary-color-span;
      font-size: 0.35em;
      line-height: 3em;
      width: 3em;
    }

    &::after {
      height: 1 - (2 * $circle-width-hover);
      left: $circle-width-hover;
      top: $circle-width-hover;
      width: 1 - (2 * $circle-width-hover);
    }
  }
}
