@import 'pie_chart';

$cell-percentage-bg-color: #f2f2f2;

.statistic-cards {
  .card {
    border: none;
    border-radius: 0;
    box-shadow: $chat-shadow-light-color $chat-shadow-size;

    @include media-breakpoint-up(md) {
      border-radius: 0.25rem;
    }
  }

  .card-title, .card-text {
    text-align: center;
  }

  .card-title {
    color: $text-muted;
    font-size: 0.8rem;
  }

  .card-text {
    font-size: 1.5rem;
  }
}

.stat-cell {
  width: 7%;
}

.cell-percentage {
  background-color: $cell-percentage-bg-color;
}

.blocking-user-chart {
  svg {
    margin-top: 1.5rem;
  }
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

tspan {
  font-size: 11px;
}
