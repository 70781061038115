$nav-logo-size: 65px;
$nav-item-font-size: 20px;
$nav-item-font-color: #2e384d;
$nav-item-active-marker-pos: 18px;
$nav-gradient-from: $green;
$nav-gradient-to: $blue;
$nav-gradient-height: 4px;
$nav-active-color: $blue;
$side-nav-box-shadow: rgba(0, 0, 0, .3) 0 2px 4px 0;
$side-nav-bg: #fff;
$side-nav-padding-x: .75rem;
$side-nav-padding-y: 1.25rem;
$side-nav-active-border: 3px;
$side-nav-active-color: $green;
$side-nav-font-size: 18px;
$side-nav-font-color: #808080;
$side-nav-width: calc(22.5px + #{2 * $side-nav-padding-y} + #{2 * $side-nav-active-border});

nav.navbar {
  font-size: $nav-item-font-size;
  font-weight: lighter;

  .navbar-brand > img {
    height: $nav-logo-size;
    width: $nav-logo-size;
  }

  .nav-item {
    position: relative;

    @include media-breakpoint-up(md) {
      &.active::after {
        background: $nav-active-color;
        bottom: calc(-#{$navbar-padding-y} - #{$nav-item-active-marker-pos});
        content: '';
        display: block;
        height: 2 * $nav-gradient-height;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
      }
    }

    a.nav-link  {
      color: $nav-item-font-color;
    }
  }
}

.navbar-gradient {
  background: linear-gradient(to right, $nav-gradient-from, $nav-gradient-to);
  display: block;
  height: $nav-gradient-height;
  left: 0;
  position: absolute;
  right: 0;

  & + * {
    margin-top: 1rem;
  }
}

.nav-container {
  border-bottom: $gray-500 solid 0.05rem;
}

.navbar-authenticated-home {
  .profile-icon {
    border-radius: 50%;
    height: 32px;
    margin-top: -2px;
    object-fit: cover;
    width: 32px;
  }

  .navbar-brand-text {
    align-items: center;
    color: #808080;
    display: flex;
  }

  .brand-image {
    margin: 0 0.625rem;
  }

  .profile-name {
    margin-right: .625rem;
  }

  .profile {
    margin-right: 0.9rem;

    .nav-link {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .navbar-brand:hover {
    color: $blue;
  }
}

.navbar-icon {
  font-size: 1.4rem;
}

.side-nav {
  align-items: flex-start;
  background-color: $side-nav-bg;
  bottom: 0;
  box-shadow: $side-nav-box-shadow;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 10;

  & + * {
    margin-left: $side-nav-width;
  }

  > * {
    align-items: center;
    border-left: $side-nav-active-border solid transparent;
    border-right: $side-nav-active-border solid transparent;
    display: flex;
    justify-content: left;
    padding: $side-nav-padding-x $side-nav-padding-y;
    width: 100%;

    &:first-child {
      justify-content: center;
      margin-bottom: 15vh;
      margin-top: 13px;
    }

    &:hover, &:active, &.active {
      background-color: $light-green;
      text-decoration: none;
    }

    &.active {
      border-left: $side-nav-active-border solid $side-nav-active-color;
    }

    &, & > i {
      color: $side-nav-font-color;
      font-size: 20px;
    }

    .explanation {
      font-size: $side-nav-font-size;
      font-weight: lighter;
      line-height: 20px;
      padding-left: 10px;

      &:hover {
        color: inherit;
      }
    }
  }
}

