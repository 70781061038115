$ie-warning-link-color: #000;

.ie-warning {
  display: none;

  &.needed {
    display: block;
    margin: 2rem;

    a {
      color: $ie-warning-link-color;
      padding-left: 0.5rem;
    }
  }
}
