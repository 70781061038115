.pricing-table {
  margin: 0;
  width: 100%;

  .subtitle-row > td, p.subtitle-row {
    color: $gray;
    font-size: 0.75rem;
    font-weight: 300;
    padding-bottom: 10px;
    padding-top: 0;
  }

  .mwst {
    font-size: 12px;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }

  .mobile {
    margin: 10px auto;
    overflow: hidden;
    text-align: center;
    width: 300px;

    .navigation {
      margin-top: 20px;

      a {
        border-radius: 10px;
        color: $black;
        margin: 7px;
        padding: 3px 6px;
      }
    }

    .slides {
      -webkit-overflow-scrolling: touch;
      display: flex;
      overflow-x: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;

      .slide {
        flex-shrink: 0;
        padding-top: 50px;
        scroll-snap-align: start;
        width: 300px;

        p {
          margin-bottom: 0;
          margin-top: 5px;
        }

        p.subtitle-row {
          margin-top: 0;
        }
      }
    }
  }

  .desktop {
    display: none;

    tbody {
      min-width: 100%;
      white-space: nowrap;
    }

    .highlighted-row {
      background-color: $light-green;

      td {
        background-color: $light-green;
      }
    }

    td,
    th {
      padding: 10px;
      text-align: center;

      &:first-child {
        text-align: left;
        width: 20%;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
}
