$avatar-form-border-color: #979797;
$avatar-form-box-shadow-color: rgba(0, 0, 0, 0.5);
$avatar-form-cropper-js-color: #39f;

.avatar-form {
  img {
    border: 1px solid $avatar-form-border-color;
    box-shadow: inset $avatar-form-box-shadow-color 2px 2px 6px 0;
    display: block;
    margin: 0 auto;
    max-width: 95%;
  }

  .cropper-container {
    max-width: 100% !important;

    .cropper-face, .cropper-view-box  {
      border-radius: 50%;
    }

    .cropper-view-box {
      box-shadow: 0 0 0 1px $avatar-form-cropper-js-color;
      outline: 0;
    }

    .dashed-v {
      height: calc(100% - 10px);
      top: 5px;
    }

    .dashed-h {
      left: 5px;
      width: calc(100% - 10px);
    }

    .point-ne, .point-nw, .point-sw, .point-se, .line-e, .line-w, .line-n, .line-s {
      display: none;
    }
  }
}
