@use 'sass:math';

$height: $chat-input-height + $chat-input-margin-top;

.input {
  background: $chat-input-bg;
  box-shadow: $chat-input-shadow;
  margin: $chat-input-margin-top 0 0;
  padding: $chat-input-padding;
  position: relative;

  .field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .field-toggle {
    color: $chat-input-icons-color;
    cursor: pointer;
    font-size: 26px;
    margin: 0 4px;
    padding: 3px;

    &.active {
      color: $chat-input-icons-active-color;
    }
  }

  .emoji-picker {
    left: 0;
    position: absolute;
    top: -365px;
    z-index: 4;

    .emoji-mart {
      border: none;
      border-radius: 0;
      box-shadow: $shadow-light-color $shadow-size;
    }
  }

  .send, .plan {
    border-radius: 50%;
    box-shadow: $shadow-light-color $shadow-size;
    color: $input-color;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: -1;
  }

  .send {
    background: $chat-input-submit-plan-bg-color;

    &::before {
      color: $chat-input-submit-send-color;
      content: '\f1d8';
    }

    &:hover {
      background: lighten($chat-input-submit-plan-bg-color, 30%);
    }
  }

  .plan {
    background: $chat-input-submit-send-bg-color;

    &::before {
      color: $chat-input-submit-plan-color;
      content: '\f133';
    }

    &:hover {
      background: darken($chat-input-submit-send-bg-color, 5%);
    }
  }

  textarea {
    appearance: none;
    background: $chat-input-textarea-bg;
    border-radius: 25px !important;
    flex-grow: 1;
    margin: 0 8px;
    min-height: 50px;
    padding: 13px $chat-input-padding;
    resize: none;
    width: auto;
  }

  input[type='file'] {
    bottom: 15px;
    color: $chat-input-icons-color;
    left: -77px;
    opacity: 0;
    right: auto;
    width: 30px;
  }

  input[type='file'] .plan {
    cursor: pointer;
    margin: 0;
    position: absolute;
    width: 60px;
  }

  input[type='file']:disabled + .fal {
    cursor: auto;
  }

  input[type='file'] + .fal, .send, .plan {
    align-items: center;
    display: flex;
    font-size: $chat-input-upload-icon-size;
    justify-content: center;
    z-index: 1;
  }

  .preview-image {
    align-items: center;
    background: $chat-msg-sent-bg;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: $chat-msg-border-radius;
    box-shadow: $chat-shadow-dark-color $chat-shadow-size;
    display: flex;
    height: $chat-input-upload-preview-image-max-height;
    justify-content: center;
    object-fit: contain;
    padding: 3px;
    position: absolute;
    right: 0;
    top: -$chat-input-upload-preview-image-max-height + 10px;
    width: $chat-input-upload-preview-image-max-width;
    z-index: 3;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    i {
      cursor: pointer;
      font-size: $chat-input-upload-icon-size;
      position: absolute;
      right: 15px;
      text-shadow: $chat-shadow-light-color $chat-shadow-size;
      top: 10px;
    }
  }

  .broadcast-plan {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    .form-check {
      padding-bottom: math.div($chat-input-padding, 2);
      padding-top: math.div($chat-input-padding, 2);
    }

    & > * {
      display: flex;
    }

    .rdtPicker {
      bottom: $input-height;
      margin-bottom: 4px;
    }
  }

  .alert {
    margin: $chat-input-padding 0 0;
  }
}

