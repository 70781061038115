$chat-input-height: $chat-input-height + $chat-input-margin-top;

.messages-container {
  margin-top: 3rem;
}

.panel {
  background: $background-color;
  box-shadow: $panel-shadow;
  height: $chat-max-height;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    margin: 0 $panel-margin-side;
  }

  &.with-chat {
    background: $chat-backgroud;
    display: grid;
    grid-template-areas: 'list chat' 'list input';
    grid-template-columns: $list-width auto;
    grid-template-rows: 1fr;

    .list {
      grid-area: list;
    }

    .chat {
      grid-area: chat;
    }

    .input {
      grid-area: input;
    }

    .no-rooms {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &.with-section {
    height: auto;

    section {
      display: none;
      padding: 2.5rem;

      &.active {
        display: block;
      }
    }
  }
}

.panel .not-ready {
  align-items: center;
  background: $background-color;
  bottom: 0;
  display: flex;
  font-size: 20px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  > * {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-not-ready;
    animation-timing-function: ease;
  }
}

@keyframes bounce-not-ready {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}
