$width: $list-width - $list-and-chat-gap;

.list {
  align-self: flex-start;
  border: $list-border;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 100%;
  overflow-x: scroll;

  scrollbar {
    width: 0;
  }

  @include avatar($list-avatar-size, $chat-shadow-light-color, $list-shadow-size);

  &::after {
    background: $list-bg;
    bottom: 0;
    box-shadow: $list-shadow-facing-chat;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: $width;
  }

  .item {
    border-bottom: $list-item-border;
    cursor: pointer;
    display: grid;
    grid-column-gap: $chat-msg-items-gap;
    grid-template-areas: 'avatar name time time' 'avatar teaser teaser notifications';
    grid-template-columns: $list-avatar-size 1fr auto minmax($list-item-badge-size, auto);
    padding: $list-item-padding;
    width: $width;
    z-index: 1;

    &.selected {
      background: $list-item-selected-bg;
      border-bottom: $list-item-selected-border;
      cursor: initial;
      z-index: 2;
    }

    .name {
      color: $list-item-name-color;
      grid-area: name;
    }

    .teaser {
      grid-area: teaser;
    }

    .time {
      grid-area: time;
      text-align: right;
    }

    .notifications {
      background-color: $list-item-badge-bg-color;
      border-radius: 20px;

      box-sizing: content-box;
      color: $list-item-badge-color;

      display: inline-block;
      font-size: $list-item-badge-font-size;

      font-weight: $list-item-badge-font-weight;
      grid-area: notifications;

      height: $list-item-badge-size;
      line-height: $list-item-badge-size;
      padding: 0 5px;
      text-align: center;
      vertical-align: top;
    }

    .name, .teaser {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name, .teaser, .time {
      font-size: $list-item-font-size;
      line-height: $list-item-line-height;
    }

    .teaser, .time {
      color: $list-item-teaser-time-color;
      font-weight: lighter;
    }
  }
}
