.content-guide-header {
  margin-bottom: -2rem;
  margin-top: 6rem;

  @include media-breakpoint-up(sm) {
    margin-top: 4rem;
  }
}

.content-guide-button {
  font-size: 1.5rem;
  margin-top: 2rem;
}

@include media-breakpoint-up(sm) {
  .content-guide-container {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;

    .content-guide-image {
      width: 45%;
    }

    .content-guide-text {
      border-left: 2px solid $green;
      margin-left: 3rem;
      padding-left: 1rem;
      padding-top: 0.5rem;
      width: 100%;
    }
  }

  .content-guide-container:nth-child(odd) {
    flex-direction: row;

    .content-guide-text {
      margin-left: 0;
      margin-right: 3rem;
      padding-right: 1rem;
    }
  }
}
