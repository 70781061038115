@import '../src/stylesheets/variables';
@import '../src/stylesheets/mixins';

@import '~bootstrap/scss/bootstrap';
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~react-datetime/css/react-datetime';
@import 'cropperjs/dist/cropper.css';
@import 'emoji-mart/css/emoji-mart.css';
@import 'react-toastify/dist/ReactToastify.css';

@import '../src/stylesheets/fonts';
@import '../src/stylesheets/home';
@import '../src/stylesheets/footer';
@import '../src/stylesheets/admin';
@import '../src/stylesheets/shop';
@import '../src/stylesheets/nav';
@import '../src/stylesheets/messages/messages';
@import '../src/stylesheets/login';
@import '../src/stylesheets/form';
@import '../src/stylesheets/static_page';
@import '../src/stylesheets/pricing_table';
@import '../src/stylesheets/shop_settings';
@import '../src/stylesheets/avatar_form';
@import '../src/stylesheets/main';
@import '../src/stylesheets/statistics';
@import '../src/stylesheets/toast';
@import '../src/stylesheets/dialog';
@import '../src/stylesheets/content_guide';
