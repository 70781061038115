@import 'messages/variables';
@import 'messages/chat';

.welcome-container {
  margin: auto;
  padding-top: 4.4rem;
  position: relative;
  width: 100%;
}

.welcome-section {
  padding-top: 3.1rem;
  text-align: left;

  h1 {
    font-size: 3.1rem;
    font-weight: 300;
  }

  .welcome-subtitle {
    display: inline;
    font-size: 1.5rem;
    font-weight: 100;
    margin-right: 0.6rem;
  }

  img {
    display: none;
    position: absolute;
    z-index: -1;
  }

  @include media-breakpoint-up(md) {
    img {
      display: block;
      right: -1.9rem;
      top: 3.1rem;
      width: 21rem;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 4.4rem;
    }

    .welcome-subtitle {
      font-size: 3.1rem;
    }

    img {
      right: 0;
      width: 25rem;
    }
  }

  @include media-breakpoint-up(xl) {
    h1 {
      font-size: 5.6rem;
    }

    .welcome-subtitle {
      font-size: 3.8rem;
    }

    img {
      top: 0;
      width: 30.5rem;
    }
  }
}

.call-to-action-section {
  margin: 5rem 0;

  .btn-home {
    background-color: $green;
    color: $anthrazit;
    width: 100%;
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    width: 20.6rem;
  }
}

.skyline-section {
  padding-top: 7rem;
  width: 100%;

  .video-embed {
    border-radius: 5px;
    box-shadow: 1px 3px 10px rgba($black, .3);
    height: 281px;
    overflow: hidden;
    width: 100%;

    @include media-breakpoint-up(sm) {
      height: 287px;
      width: 510px;
    }

    @include media-breakpoint-up(md) {
      height: 388px;
      width: 690px;
    }

    @include media-breakpoint-up(lg) {
      height: 248px;
      width: 440px;
    }

    @include media-breakpoint-up(xl) {
      height: 281px;
      width: 500px;
    }
  }

  .content {
    margin-top: -2px;
    padding: 3em 0;
  }

  .tile {
    padding: .5em 1em;

    h4 {
      font-weight: 300;
      margin-bottom: .6em;
    }
  }
}

.bonus-section,
.loyalty-section {
  .feature-row {
    margin-bottom: 1.5em;
  }

  .icon {
    align-items: center;
    background-color: $white;
    border-radius: 9px;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
    color: $blue;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 2em;
    height: 80px;
    justify-content: center;
    margin-right: 1em;
    width: 80px;
  }

  .laptop-container {
    margin-bottom: 1em;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    .laptop-container {
      margin-bottom: 0;

      img {
        height: 500px;
        width: auto;
      }
    }
  }
}

.facts-section {
  .chat {
    $chat-avatar-size: 60px;
    $chat-avatar-spacing: 5px;
    $avatar-column-width: $chat-avatar-size + $chat-avatar-spacing;

    .avatar > img {
      height: $chat-avatar-size;
      margin: $chat-avatar-spacing;
      width: $chat-avatar-size;
    }

    .message.received {
      grid-template-columns: $avatar-column-width calc(100% - #{$avatar-column-width + $list-and-chat-gap});
    }

    .message.sent {
      grid-template-columns: calc(100% - #{$avatar-column-width + $list-and-chat-gap}) $avatar-column-width;
    }
  }
}

.features {
  font-size: 1.25rem;
  font-weight: 300;
  margin: auto;
  max-width: 31.25rem;
  padding-bottom: 1.25rem;

  .btn-home {
    color: $green;
    margin: 2rem auto;
  }

  .btn-container {
    margin: auto;
    width: 12.5rem;
  }

  ul > li {
    font-size: 1.7rem;
    margin: 0.6rem 0;
  }
}

.btn-home {
  background-color: $white;
  border-radius: 10px;
  box-shadow: -3px 2px 7px rgba(0, 0, 0, .3);
  font-size: 1.3em;
  padding: 0.8em 3em;

  &:hover {
    background-color: darken($white, 2%);
  }
}

.blue {
  color: $blue;
}

.green {
  color: $green;
}

.content-banner {
  margin: 0 auto 3.125rem;
  max-width: 43.75rem;
}

.section-title {
  font-weight: 300;
  padding: 1.56rem 0;
}

.sign-up-banner {
  background-color: $light-green;
  margin-top: 1.87rem;
  width: 100%;

  .container {
    padding: 1.56rem 0.6rem 1.875rem;
    text-align: center;
  }

  .btn-home {
    background-color: $green;
    color: $anthrazit;
    display: inline-block;
    margin: 1.25rem;
    padding: 0.625rem 1.5625rem;
  }
}

.avatar-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: avatar;
  justify-content: flex-end;
  width: 100%;
}

.bg-light-gray {
  background-color: $light-gray;
}

.rotated-triangle {
  transform: rotate(180deg);
}
