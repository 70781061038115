@mixin avatar($size, $shadow-light-color, $shadow-size) {
  .avatar {
    grid-area: avatar;
    position: relative;

    & > img {
      background: #fff;
      border-radius: 50%;
      box-shadow: $shadow-light-color $shadow-size;
      height: $size;
      object-fit: cover;
      width: $size;
    }

    &.online::after {
      background: #0f0;
      border: 2px solid #fff;
      border-radius: 50%;
      content: '';
      height: 15px;
      position: absolute;
      right: 0;
      top: 0;
      width: 15px;
      z-index: 3;
    }
  }
}
