@use 'sass:math';

.chat {
  margin-top: 2 * $chat-gap;
  overflow-x: scroll;
  padding-right: $chat-gap;

  @include avatar($chat-avatar-size, $chat-shadow-light-color, $chat-shadow-size);

  .avatar {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  .message {
    display: grid;
    grid-column-gap: $chat-msg-items-gap;
    grid-row-gap: $chat-msg-items-gap;
    width: 100%;

    & + .message {
      margin-top: $chat-msg-space-between-msgs;
    }

    &.received {
      grid-template-areas: 'avatar name' 'avatar content';
      grid-template-columns: $chat-avatar-size calc(100% - #{$chat-avatar-size + $list-and-chat-gap});
      justify-items: left;

      .content {
        background: $chat-msg-received-bg;
        border-bottom-left-radius: 0;
        box-shadow: $chat-shadow-light-color $chat-shadow-size;

        &::before, &::after {
          left: 0;
        }

        &::after {
          color: $chat-msg-received-bg;
          content: '◤';
          text-shadow: $chat-shadow-light-color $chat-shadow-size;
        }
      }

      .name {
        padding-left: $chat-msg-border-radius - 5px;
      }
    }


    &.sent, &.planned, &.sending {
      grid-template-areas: 'name avatar' 'content avatar';
      grid-template-columns: calc(100% - #{$chat-avatar-size + $list-and-chat-gap}) $chat-avatar-size;
      justify-items: right;

      .content {
        border-bottom-right-radius: 0;
        box-shadow: $chat-shadow-dark-color $chat-shadow-size;

        &::before, &::after {
          right: 0;
        }

        &::after {
          content: '◥';
          text-shadow: $chat-shadow-dark-color $chat-shadow-size;
        }
      }

      .name {
        padding-right: $chat-msg-border-radius - 5px;
      }
    }

    &.sent {
      .content {
        background: $chat-msg-sent-bg;

        &::after {
          color: $chat-msg-sent-bg;
        }
      }
    }

    &.planned .content {
      background: $chat-msg-planned-bg;

      &::after {
        color: $chat-msg-planned-bg;
      }
    }

    &.sending {
      .content {
        background: $chat-msg-sending-bg;

        &::after {
          color: $chat-msg-sending-bg;
        }
      }
    }

    &.sent + .planned, &.received + .planned, &.sending + .planned {
      margin-top: $chat-msg-divider-space-height;
      position: relative;

      &::before {
        background: #000;
        content: '';
        display: block;
        height: $chat-msg-divider-height;
        position: absolute;
        top: math.div(-($chat-msg-divider-space-height - $chat-msg-divider-height), 2);
        width: 100%;
      }
    }

    .name {
      color: $chat-msg-name-text-color;
      font-size: $chat-msg-name-text-size;

      font-weight: normal;
      grid-area: name;
      line-height: $chat-msg-name-text-size;
    }

    .content {
      border-radius: $chat-msg-border-radius;
      color: $chat-msg-text-color;
      font-size: $chat-msg-text-size;
      grid-area: content;
      line-height: $chat-msg-line-height;
      margin-bottom: $chat-msg-triangle-size * .66;
      max-width: 80%;
      min-width: 120px;
      padding: 3px;
      position: relative;

      .actions {
        display: none;
      }

      &:hover {
        .actions {
          display: block;
          font-size: 0.75rem;
          position: absolute;
          right: 3px;
          top: 3px;

          * {
            cursor: pointer;
            margin-right: 5px;
          }

          & + .text {
            padding-top: $chat-msg-padding-height;
          }
        }

      }

      .quote {
        background-color: rgba($gray-900, 0.2);
        border-left: 5px solid #55ab85;
        border-radius: 0 10px 10px 0;
        color: $white;
        margin: $chat-msg-padding-height $chat-msg-padding-width + 20px 5px $chat-msg-padding-width - 5px;
        padding: 5px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .text {
        padding: $chat-msg-padding-height $chat-msg-padding-width + 20px 5px $chat-msg-padding-width;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .time {
        color: $chat-msg-time-text-color;
        font-size: $chat-msg-time-text-size;
        line-height: $chat-msg-time-text-size;
        padding-bottom: $chat-msg-time-padding;
        padding-right: $chat-msg-time-padding;
        text-align: right;
      }

      img {
        border-radius: $chat-msg-border-radius;
        cursor: pointer;
        display: block;
        max-height: $chat-msg-img-max-height;
        max-width: 100%;
        object-fit: contain;
        padding-bottom: $chat-msg-space-after-img;

        & + .text {
          padding-top: $chat-msg-padding-height - $chat-msg-space-after-img;
        }
      }

      &::before {
        background-color: inherit;
        bottom: 0;
        content: '';
        display: block;
        height: 10px;
        position: absolute;
        width: 20px;
        z-index: 1;
      }

      &::after {
        bottom: -$chat-msg-triangle-size;
        font-size: $chat-msg-triangle-size;
        position: absolute;
      }
    }
  }

  .quota {
    color: $chat-msg-name-text-color;
    font-size: $chat-msg-name-text-size;
    font-weight: normal;
  }
}
