footer {
  .links {
    background-color: $dark-blue;
    padding: 3.75rem 0;

    .title {
      font-size: $footer-font-size;
      padding-bottom: 1rem;
    }

    a {
      color: $white;
    }
  }

  .swiss-made {
    background-color: $black;
    padding: 1.5rem 0;

    img {
      height: 2.5rem;
      margin-top: 0.62rem;
    }

    @include media-breakpoint-up(md) {
      .container {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      img {
        margin: 0;
      }
    }
  }

  .supporters {
    background-color: $gray-200;
    padding-bottom: 3.75rem;

    .footer-icons {
      align-items: center;
    }

    .title {
      padding-bottom: 1rem;
      padding-top: 1.6rem;
      @include media-breakpoint-up(sm) {
        padding-bottom: 3.2rem;
      }
    }

    img {
      filter: grayscale(100%);
      margin: 1.25rem;
      max-width: 9.37rem;

      @include media-breakpoint-up(sm) {
        margin: 0;
        max-width: 70%;
      }
    }

    .press {
      img {
        margin-bottom: 2rem;
        max-height: 3rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 3.125rem;
        }
      }
    }
  }
}
