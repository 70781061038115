.lightbox {
  align-items: center;
  background: $lightbox-bg-color;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  .pinch-zoom-container {
    background: $lightbox-bg-color;
    height: 100vh !important;
    width: 100vw;

    @include media-breakpoint-up(md) {
      background: transparent;
      height: $lightbox-max-height-desktop !important;
      width: $lightbox-max-width-desktop;
    }
  }

  &::before {
    color: $lightbox-icon-color;
    content: '\f00d';
    cursor: pointer;
    font-family: 'Font Awesome 5 Pro';
    font-size: 36px;
    font-weight: 100;
    position: fixed;
    right: 1rem;
    top: 1rem;
    z-index: 11;
  }
}
