// custom
$anthrazit: #222;
$blue: #009dff;
$dark-blue: #2e384d;
$green: #0fe70f;
$black: #000;
$light-green: #e4ffe4;
$gray: #808080;
$light-gray: #f4f5fb;
$white: #fff;
$red: #f00;

$footer-font-size: 1.25rem;
$testimonials-shadow: 0 1rem 3rem rgba($black, .175) !default;

// bootstrap
$navbar-padding-x: 0;
$navbar-light-hover-color: $blue;
$primary: $green;
$secondary: #f4f6fc;
$yiq-contrasted-threshold: 140;
$btn-border-radius: 1px;
$font-family-base: 'Roboto';
$dashboard-transition: all 0.7s cubic-bezier(.25, .8, .25, 1);
$body-color: $anthrazit;
$modal-inner-padding: 2rem;
$carousel-control-color: $green;
