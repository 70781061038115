h2 {
  font-weight: 300 !important;
}

.testimonials {
  margin: auto;

  .testimonial-shadow {
    box-shadow: $testimonials-shadow;
  }
}
