$background-color: #fff;
$shadow-size: 0 2px 4px;
$shadow-light-color: rgba(0, 0, 0, .22);

$list-bg: $background-color;
$list-shadow-facing-chat: rgba(207, 208, 212, 0.8) 0 10px 20px 0;
$list-and-chat-gap: 20px;
$list-width: 400px;
$list-avatar-size: 40px;
$list-shadow-size: $shadow-size;
$list-shadow-light-color: $shadow-light-color;
$list-border: rgba(153, 153, 153, .1);
$list-item-padding: 20px 25px;
$list-item-border: 1px solid #f4f4f4;
$list-item-selected-bg: #ebffeb;
$list-item-selected-border: 1px solid #e0f4e0;
$list-item-name-color: #2e384d;
$list-item-font-size: 15px;
$list-item-line-height: 22px;
$list-item-teaser-time-color: #8798ad;
$list-avatar-size: 40px;
$list-item-badge-size: 20px;
$list-item-badge-font-size: 12px;
$list-item-badge-font-weight: 500;
$list-item-badge-color: #fff;
$list-item-badge-bg-color: $green;

$panel-shadow: rgba(219, 220, 224, 0.8) 0 0 5px;
$panel-margin-side: 30px;

$chat-max-height: calc(100vh - 220px);
$chat-backgroud: $background-color url('../images/chat-bg.svg') repeat 0 0 / 150px 150px;
$chat-avatar-size: 50px;
$chat-shadow-size: $shadow-size;
$chat-shadow-light-color: $shadow-light-color;
$chat-shadow-dark-color: rgba(0, 0, 0, .5);
$chat-gap: 10px;
$chat-msg-padding-height: 20px;
$chat-msg-padding-width: 15px;
$chat-msg-items-gap: 8px;
$chat-msg-space-between-msgs: 12px;
$chat-msg-border-radius: 17px;
$chat-msg-received-bg: #d7ffd7;
$chat-msg-sent-bg: $background-color;
$chat-msg-text-color: #000;
$chat-msg-text-size: 18px;
$chat-msg-line-height: 24px;
$chat-msg-time-text-color: #999;
$chat-msg-time-text-size: 12px;
$chat-msg-time-padding: 5px;
$chat-msg-name-text-color: #999;
$chat-msg-name-text-size: 13px;
$chat-msg-triangle-size: 12px;
$chat-msg-space-after-img: 10px;
$chat-msg-img-max-height: 30vh;
$chat-msg-planned-bg: #d7f7ff;
$chat-msg-sending-bg: $chat-msg-sent-bg;
$chat-msg-divider-space-height: 50px;
$chat-msg-divider-height: 3px;
$chat-msg-divider-color: #000;

$chat-input-bg: $background-color;
$chat-input-padding: 20px;
$chat-input-shadow: rgba(204, 204, 204, 0.8) 0 2px 5px;
$chat-input-margin-top: 30px;
$chat-input-textarea-bg: #f4f6fc;
$chat-input-height: 100px;
$chat-input-margin-top: 20px;
$chat-input-upload-icon-size: 24px;
$chat-input-upload-width: 70px;
$chat-input-upload-preview-image-max-height: 200px;
$chat-input-upload-preview-image-max-width: 200px;
$chat-input-submit-size: 50px;
$chat-input-submit-plan-color: #000;
$chat-input-submit-plan-bg-color: $green;
$chat-input-submit-send-color: #000;
$chat-input-submit-send-bg-color: #d7f7ff;
$chat-input-icons-color: #6c757c;
$chat-input-icons-active-color: $green;

$lightbox-max-height-desktop: 80vh;
$lightbox-max-width-desktop: 90vw;
$lightbox-bg-color: rgba(0, 0, 0, .9);
$lightbox-icon-color: #fff;
