input.form-control {
  border: 0.06rem solid $gray-500;
  border-radius: 0;
}

textarea.form-control {
  border: 0.06rem solid $gray-500;
  border-radius: 0;
}

.btn-primary {
  backface-visibility: hidden;
  background-color: $green;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 0.06rem rgba(0, 0, 0, 0);
  transform: translateZ(0);
  transition: all 0.3s;
  vertical-align: middle;

  &:hover, &:focus, &:active {
    background-color: lighten($green, 30%);
    box-shadow: 0 0.6rem 0.6rem -0.6rem rgba(0, 0, 0, 0.5);
    color: $black;
  }
}

.btn-upload {
  background-color: $background-color;
  border: 1px solid $gray-500;
  cursor: pointer;

  &:hover, &:focus, &:active {
    background-color: darken($background-color, 20%);
    box-shadow: 0 0.6rem 0.6rem -0.6rem rgba(0, 0, 0, 0.5);
  }

  & + * {
    display: none;
  }
}

.btn-icon {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.link-green {
  color: $green;
}

.centered-form {
  padding-top: 4rem;

  .form-control-label,
  .check_boxes > .col-form-label {
    font-weight: 300;
    text-transform: uppercase;
  }

  .form-check {
    .form-check-label {
      font-weight: 300;
      margin-left: 1rem;
    }

    .invalid-feedback {
      margin-left: 1rem;
    }
  }

  input[type='submit'] {
    margin-top: 1rem;
    min-width: 10rem;
  }
}

.shop_interest_groups .collection_check_boxes {
  margin-bottom: 0;
}
