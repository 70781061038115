$shop-settings-border-color: #e4e8f0;

.shop-settings {
  &, h1 {
    font-weight: lighter;
  }

  h1 {
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    form > .row {
      flex-wrap: nowrap;
    }
  }

  .avatar-form {
    .form-group {
      text-align: center;
    }
  }

  input[type='submit'] {
    display: block;
    margin-bottom: 1rem;
  }

  .form-control-label, label.file, .form-selection label, .form-check-label {
    font-size: .75rem;
    text-transform: uppercase;
  }

  .opening-hours {
    padding: .75rem 0;

    & + .opening-hours {
      border-top: 1px solid $shop-settings-border-color;
    }

    .day {
      align-items: center;
      display: flex;
      width: 7.7rem;

      h5 {
        font-weight: lighter;
        margin-bottom: 0;
      }
    }
  }

  .selects-in-line {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    padding: .25rem 0;

    select {
      background-image: none;
      font-size: 14px;
      margin: 0 .25rem;
      text-align-last: center;
      width: min-content;
    }
  }

  .form-selection {
    align-self: flex-start;
    border-left: 1px solid $shop-settings-border-color;
    min-height: 200px;

    .day {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: .25rem;

      .time {
        margin-left: auto;
        width: 50%;
      }
    }
  }

  *[name='shop[auto_responder_message]'] {
    min-height: 120px;
  }

  .form-check-input.is-valid ~ .form-check-label {
    color: inherit;
  }
}

.selected {
  background-color: rgba($primary, .6);
  box-shadow: rgba(219, 220, 224, 0.8) 0 0 5px;

  .price {
    color: $white;
  }
}
