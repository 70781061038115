.dashboard-link {
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}

.dashboard-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3.75rem;
}

.dashboard-card {
  display: inline-block;
  padding: 1rem;
}

.dashboard-link-container {
  background: $white;
  border-radius: 0.125rem;
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.24);
  display: inline-block;
  height: 10rem;
  position: relative;
  text-align: center;
  transition: $dashboard-transition;
  width: 10rem;

  @include media-breakpoint-up(md) {
    height: 12rem;
    width: 12rem;
  }

  @include media-breakpoint-up(xl) {
    height: 14rem;
    width: 14rem;
  }

  &:hover {
    background: $green;
    box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, 0.25), 0 0.625rem 0.625rem rgba(0, 0, 0, 0.22);

    .dashboard-icon {
      color: $white;
    }
  }

  .dashboard-link-name {
    color: $gray-700;
  }

  .dashboard-icon {
    color: $green;
    font-size: 4rem;
    margin: auto;
    padding: 1rem;
    transition: $dashboard-transition;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 6rem;
    }
  }
}

.home-container {
  margin: 0.625rem 1rem;
  @include media-breakpoint-up(md) {
    margin: 0.625rem 4rem;
  }
}

@include media-breakpoint-up(md) {
  .skyline {
    margin-top: -2.5rem;
  }
}

.page-title {
  font-size: 2rem;
  font-weight: 300;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
    padding: 2.3rem 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }
}

.page {
  min-height: 100vh;
}

.opening_hours {
  width: 40em;
}
